<template>
  <div style="position: relative; height: 100%; width:100%; overflow: hidden">
    <div id="mapSearch" @mouseleave="deSelect"/>
<!--    <DrawTool style="position: absolute; top: 60px; right: 10px" @changeMode="changeMode"/>-->
    <v-card width="80" height="80" style="position: absolute; bottom: 50px; right: 60px; border: 2px solid white">
      <v-layout>
        <v-menu left>
          <template v-slot:activator="{ on, attrs }">
            <div
                style="width: 76px; height: 76px; background-size: 80px 80px"
                v-bind="attrs"
                v-on="on"
                :style="{'background-image': isBaseMap ? `url(${require('@/assets/images/street_map.jpg')}` : `url(${require('@/assets/images/satellite.webp')}`}"
            >
            </div>
          </template>
          <v-list dense>
            <v-list-item @click="displaySatellite('none')">
              <v-list-item-action>
                <div style="min-width: 50px; height: 50px; border: 1px solid lightgrey; border-radius: 4px"
                     :style="{'background-image': `url(${require('@/assets/images/street_map.jpg')}`}"
                >
                </div>
              </v-list-item-action>
              <v-list-item-title>
                Base map
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="displaySatellite('visible')">
              <v-list-item-action>
                <div style="min-width: 50px; height: 50px; border: 1px solid lightgrey; border-radius: 4px; background-size: 50px 50px"
                     :style="{'background-image': `url(${require('@/assets/images/satellite.webp')}`}"
                >
                </div>
              </v-list-item-action>
              <v-list-item-title>
                Satellite
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-layout>
    </v-card>
    <v-card class="map-info">
      <v-layout class="fill-height" align-center justify-center>
        <v-icon x-small class="mr-2">mdi-crosshairs-gps</v-icon>
        <div style="font-size: 12px">{{ center.lng.toFixed(4) + ' ' + center.lat.toFixed(4) }}</div>
        <v-icon class="mx-2" x-small>mdi-magnify</v-icon>
        <div style="font-size: 12px">{{ zoom }}</div>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw'
import DrawRectangleAssisted from '@geostarters/mapbox-gl-draw-rectangle-assisted-mode'
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode'
import {CircleMode, DirectMode, DragCircleMode, SimpleSelectMode,} from '@/ultis/draw'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import DrawTool from "@/components/home/draw/DrawTool";
import {RulerControl, CompassControl, ZoomControl} from 'mapbox-gl-controls'
import bbox from '@turf/bbox'

let map
let draw
export default {
  components: {DrawTool},
  name: "BaseMap",
  data() {
    return {
      center: {lng: 0, lat: 0},
      isBaseMap: true,
      zoom: 0,
      listImage: [],
      addedLayer: undefined
    }
  },
  mounted() {
    window.mapboxgl.accessToken = 'pk.eyJ1IjoiaG9hdGllbnR1IiwiYSI6ImNrYXMwNmt4ZzA4YTIyeXAzcjZicmhsNXMifQ.9hvfCuoiO1-1cFmikE14LA'
    map = new window.mapboxgl.Map({
      container: 'mapSearch', // container id
      style: 'https://tiles.eofactory.ai/styles/basic/style.json',
      center: [105.8466249704361, 21.003883312911878], // starting position
      zoom: 2, // starting zoom,
      attributionControl: false
    })

    draw = new MapboxDraw({
      keybindings: true,
      displayControlsDefault: false,
      userProperties: true,
      controls: {
        line_string: false,
        polygon: false,
        trash: false
      },
      modes: {
        ...MapboxDraw.modes,
        draw_assisted_rectangle: DrawRectangleAssisted,
        draw_rectangle: DrawRectangle,
        draw_circle: CircleMode,
        drag_circle: DragCircleMode,
        direct_select: DirectMode,
        simple_select: SimpleSelectMode
      }
    })
    map.addControl(new window.mapboxgl.ScaleControl({
      maxWidth: 80,
      unit: 'metric'
    }), 'bottom-right')
    map.addControl(new ZoomControl(), 'bottom-right')
    map.addControl(new CompassControl(), 'bottom-right')
    // map.addControl(new RulerControl(), 'bottom-right')
    this.initDrawControlShortcuts()
    map.addControl(draw)
    map.on('style.load', () => {
      map.resize()
      this.center = map.getCenter()
      this.zoom = map.getZoom().toFixed(2)
      this.addSatellite()
    })
    map.on('draw.create', this.changeAOI)
    map.on('mousemove', (e) => {
      this.center = e.lngLat.wrap()
    })
    map.on('zoom', _ => {
      this.zoom = map.getZoom().toFixed(2)
    })
  },

  destroyed() {
    if (map) {
      map.remove()
      draw = undefined
      map = undefined
    }

    this.destroyDrawControlShortcuts()
  },
  methods: {
    deSelect() {
      draw.changeMode('simple_select')
    },
    addSatellite() {
      map.addLayer({
        "id": "satellite_bing",
        "type": "raster",
        "source": {
          "type": "raster",
          "tiles": [
            "https://ecn.t0.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=7505",
            "https://ecn.t1.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=7505",
            "https://ecn.t2.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=7505",
            "https://ecn.t3.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=7505"
          ],
          "tileSize": 256,
          "maxzoom": 18,
          "minzoom": 1,
          "bounds": [
            -180,
            -85.051129,
            180,
            85.051129
          ]
        },
        "layout": {
          "visibility": "none"
        },
        "paint": {
          "raster-opacity": 1,
          "raster-contrast": 0,
          "raster-brightness-min": 0,
          "raster-brightness-max": 1
        }
      })
    },
    displaySatellite (status) {
      this.isBaseMap = status === 'none';
      map.setLayoutProperty('satellite_bing', 'visibility', status)
    },
    initDrawControlShortcuts() {
      this.onKeyUp = function (event) {
        switch (event.key) {
          case '3':
          case 'q':
            if (draw.getMode() !== 'draw_polygon') {
              draw.changeMode('draw_polygon')
            }
            break
          case '4':
          case 'w':
            if (draw.getMode() !== 'draw_rectangle') {
              draw.changeMode('draw_rectangle')
            }
            break
          case '5':
          case 'e':
            if (draw.getMode() !== 'draw_assisted_rectangle') {
              draw.changeMode('draw_assisted_rectangle')
            }
            break
          case '6':
          case 'r':
            if (draw.getMode() !== 'drag_circle') {
              draw.changeMode('drag_circle')
            }
            break
          case 'Delete':
          case 'Backspace':
            if (draw.getSelected()) {
              draw.trash()
            }
            break
          case 'Asc':
            draw.changeMode('simple_select')
            break
          default:
            draw.changeMode('simple_select')
            break
        }
      }.bind(this)

      window.addEventListener('keyup', this.onKeyUp)
    },
    destroyDrawControlShortcuts() {
      if (this.onKeyUp) window.removeEventListener('keyup', this.onKeyUp)
    },
    changeMode(mode) {
      switch (mode) {
        case 'polygon':
          if (draw.getMode() !== 'draw_polygon') {
            draw.changeMode('draw_polygon')
          }
          break
        case 'rectangle':
          if (draw.getMode() !== 'draw_rectangle') {
            draw.changeMode('draw_rectangle')
          }
          break
        case 'assist-rectangle':
          if (draw.getMode() !== 'draw_assisted_rectangle') {
            draw.changeMode('draw_assisted_rectangle')
          }
          break
        case 'drag-circle':
          if (draw.getMode() !== 'drag_circle') {
            draw.changeMode('drag_circle')
          }
          break
        case 'drag-point':
          if (draw.getMode() !== 'draw_point') {
            draw.changeMode('draw_point')
          }
          break
        case 'delete':
          if (draw.getSelected()) {
            draw.trash()
          }
          break
        default:
          break
      }
    },
    drawAoi(geojson) {
      draw.set(geojson)
      this.changeAOI()
      map.fitBounds(bbox(geojson), {
        'duration': 0,
        'padding': 20
      })
    },
    changeAOI() {
      this.clearMap()
      if (map.getStyle().layers.some(layer => layer.id === 'aoi')) map.removeLayer('aoi').removeSource('aoi')
      let featureCollection = draw.getAll()
      this.createLayer(featureCollection)
      this.$store.commit('s', ['aoi', 'currentAOI', featureCollection])
      draw.deleteAll()
      this.$emit('changeAOI', featureCollection)
    },
    createLayer(featureCollection) {
      map.addSource('aoi', {
            type: 'geojson',
            data: featureCollection
          }
      )
      map.addLayer({
        'id': 'aoi',
        'type': 'line',
        'source': 'aoi',
        'paint': {
          'line-color': '#ff0000',
          'line-width': 3
        },
      });
    },
    deleteAll() {
      if (map.getStyle().layers.some(layer => layer.id === 'aoi')) map.removeLayer('aoi').removeSource('aoi')
      draw.deleteAll()
      this.$store.commit('s', ['aoi', 'currentAOI', null])
      this.clearMap()
    },
    addRegionBoundary(data) {
      this.clearMap()
      this.deleteAll()
      data.data.forEach(geometry => {
        draw.add(geometry)
      })
      let featureCollection = draw.getAll()
      this.createLayer(featureCollection)
      this.$store.commit('s', ['aoi', 'currentAOI', featureCollection])
      draw.deleteAll()
      this.$emit('setAoi', featureCollection)
      if (data.isZoom && data.data.length > 0) {
        map.fitBounds(bbox(data.data[data.data.length - 1]), {'duration': 0, padding: 20})
      }
    },
    addFootPrint(geometry, id) {
      this.removeFootPrint()
      try {
        map.addSource(`${id}-footprint`, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                'type': 'Feature',
                'geometry': geometry
              }
            ]
          }
        })
        let layer = {
          'id': `${id}-footprint`,
          'type': 'fill',
          'source': `${id}-footprint`,
          'paint': {
            'fill-color': '#c10000',
            'fill-opacity': 0.2
          }
        }
        map.addLayer(layer)
        this.addedLayer = layer
      } catch (e) {
        console.log(e.message)
      }
    },
    removeFootPrint() {
      if (this.addedLayer) {
        map.removeLayer(this.addedLayer.id).removeSource(this.addedLayer.id)
        this.addedLayer = undefined
      }
    },
    addImage(url, currentImage) {
      let bound
      let coordinates = []
      switch (currentImage.dataset) {
        case "planet":
        case "capella_space":
          bound = bbox(currentImage.geometry)
          coordinates = [
            [bound[0], bound[3]],
            [bound[2], bound[3]],
            [bound[2], bound[1]],
            [bound[0], bound[1]]
          ]
          break
        case 'geofen':
        case 'kompsat':
          const coords = currentImage.geometry.coordinates[0]
          coordinates = [
            coords[0], coords[3], coords[2], coords[1]
          ]
          break
        default:
          bound = bbox(currentImage.geometry)
          coordinates = [
            [bound[0], bound[3]],
            [bound[2], bound[3]],
            [bound[2], bound[1]],
            [bound[0], bound[1]]
          ]
      }
      map.addSource(currentImage.id, {
        "type": "image",
        "url": url,
        "coordinates": coordinates
      });
      map.addLayer({
        "id": currentImage.id,
        "source": currentImage.id,
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      }, 'waterway-tunnel');
      this.listImage.push(currentImage.id)
    },
    removeImage(currentImage) {
      if (this.listImage.some(val => val === currentImage.id)) map.removeLayer(currentImage.id).removeSource(currentImage.id)
    },
    clearMap() {
      this.listImage.forEach(id => {
        map.removeLayer(id).removeSource(id)
      })
      this.listImage = []
    },
    zoomToBound(currentImage) {
      this.submitZoom(bbox(currentImage.geometry))
    },
    submitZoom(bbox) {
      map.fitBounds(bbox, {
        'duration': 0,
        'padding': 20
      })
    }
  },
}
</script>

<style scoped>
.map-info {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  z-index: 2;
  background-color: #fcfaf9;
  position: absolute;
  bottom: 10px;
  right: 90px;
  width: 220px;
  height: 30px;
}

#mapSearch {
  width: 100%;
  height: 100%;
}

/deep/
.mapbox-compass {
  border-radius: 50%;
}

/deep/
.mapbox-compass button {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

/deep/
.mapbox-compass svg {
  margin-right: 2px;
  margin-top: 4px;
  width: 25px;
  height: 25px;
}

/deep/
.mapbox-zoom {
  border-radius: 30px;
  box-shadow: none;
}

/deep/
.mapbox-zoom button {
  background-color: white;
  width: 30px;
  height: 30px;
}

/deep/
.mapbox-zoom button:nth-child(1) {
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
}

/deep/
.mapbox-zoom button:nth-child(2) {
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}
/deep/
.mapboxgl-ctrl-scale {
  font-size: 12px;
  text-align: center;
  padding-top: 5px;
  height: 30px;
  width: 75px !important;
  background-color: white;
  border-radius: 30px;

}
</style>
