<template>
  <div class="box">
    <section>
      <date-picker 
        v-model="date" 
        type="date" 
        range placeholder="Select date range"
        value-type="format" 
        format="DD-MM-YYYY"
        @change="DateSelected"
        title-format='DD-MM-YYYY'
        range-separator=' - '
      ></date-picker>
    </section>
  </div>
</template>

<script>
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  export default {
    components: { DatePicker },
    data() {
      return {
        date: [new Date(2019, 9, 8), new Date(2019, 9, 19)],
      };
    },
    methods: {
        DateSelected(){
            this.$emit('changedDate', this.date)
        }
    },
  };
</script>

<style>

div.mx-range-wrapper div.mx-calendar.mx-calendar-panel-date:nth-child(1) .mx-calendar-header button.mx-btn-icon-double-right,
div.mx-range-wrapper div.mx-calendar.mx-calendar-panel-date:nth-child(1) .mx-calendar-header button.mx-btn-icon-right,
div.mx-range-wrapper div.mx-calendar.mx-calendar-panel-date:nth-child(2) .mx-calendar-header button.mx-btn-icon-left,
div.mx-range-wrapper div.mx-calendar.mx-calendar-panel-date:nth-child(2) .mx-calendar-header button.mx-btn-icon-double-left
{
    display:none
}

.mx-datepicker-range{
  width: 205px;
}

.mx-input-wrapper{
  transform: translate(0px, -6px);
}

.mx-input{
  border: none;
  color: #8539F0;
  font-weight: 700;
}

.mx-input::placeholder{
  color: #8539F0;
}

i.mx-icon-calendar svg {
  color: #8539F0;
}
</style>