<template>
  <div style="position: relative; height: 100%; width:100%; overflow: hidden">
    <div id="mapSearch" @mouseleave="deSelect"/>
    <!--    <DrawTool style="position: absolute; top: 60px; right: 10px" @changeMode="changeMode"/>-->
    <v-card class="map-info">
      <v-layout class="fill-height" align-center justify-center>
        <v-icon x-small class="mr-2">mdi-crosshairs-gps</v-icon>
        <div style="font-size: 12px">{{ center.lng.toFixed(4) + ' ' + center.lat.toFixed(4) }}</div>
        <v-icon class="mx-2" x-small>mdi-magnify</v-icon>
        <div style="font-size: 12px">{{ zoom }}</div>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw'
import DrawRectangleAssisted from '@geostarters/mapbox-gl-draw-rectangle-assisted-mode'
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode'
import {CircleMode, DirectMode, DragCircleMode, SimpleSelectMode,} from '@/ultis/draw'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import DrawTool from "@/components/home/draw/DrawTool";
import {RulerControl, CompassControl, ZoomControl} from 'mapbox-gl-controls'
import bbox from '@turf/bbox'

let map
let draw
export default {
  components: {DrawTool},
  name: "TimeSeriesMap",
  data() {
    return {
      layers: [],
      center: {lng: 0, lat: 0},
      isBaseMap: true,
      zoom: 0,
      listImage: [],
      layersImageMosaic: [],
      addedLayer: undefined
    }
  },
  mounted() {
    window.mapboxgl.accessToken = 'pk.eyJ1IjoiaG9hdGllbnR1IiwiYSI6ImNrYXMwNmt4ZzA4YTIyeXAzcjZicmhsNXMifQ.9hvfCuoiO1-1cFmikE14LA'
    map = new window.mapboxgl.Map({
      container: 'mapSearch', // container id
      style: 'https://tiles.eofactory.ai/styles/basic/style.json',
      center: [103.816945, 1.355359], // starting position
      zoom: 9.5, // starting zoom,
      attributionControl: false
    })

    draw = new MapboxDraw({
      keybindings: true,
      displayControlsDefault: false,
      userProperties: true,
      controls: {
        line_string: false,
        polygon: false,
        trash: false
      },
      modes: {
        ...MapboxDraw.modes,
        draw_assisted_rectangle: DrawRectangleAssisted,
        draw_rectangle: DrawRectangle,
        draw_circle: CircleMode,
        drag_circle: DragCircleMode,
        direct_select: DirectMode,
        simple_select: SimpleSelectMode
      }
    })
    map.addControl(new window.mapboxgl.ScaleControl({
      maxWidth: 80,
      unit: 'metric'
    }), 'bottom-right')
    map.addControl(new ZoomControl(), 'bottom-right')
    map.addControl(new CompassControl(), 'bottom-right')
    // map.addControl(new RulerControl(), 'bottom-right')
    this.initDrawControlShortcuts()
    map.addControl(draw)
    map.on('style.load', () => {
      map.resize()
      this.center = map.getCenter()
      this.zoom = map.getZoom().toFixed(2)
      this.addSatellite()
    })
    map.on('draw.create', this.changeAOI)
    map.on('mousemove', (e) => {
      this.center = e.lngLat.wrap()
    })
    map.on('click', this.onMapClick)
    map.on('zoom', _ => {
      this.zoom = map.getZoom().toFixed(2)
    })
  },

  destroyed() {
    if (map) {
      map.remove()
      draw = undefined
      map = undefined
    }

    this.destroyDrawControlShortcuts()
  },
  methods: {
    deSelect() {
      draw.changeMode('simple_select')
    },
    addSatellite() {
      map.addLayer({
        "id": "satellite_bing",
        "type": "raster",
        "source": {
          "type": "raster",
          "tiles": [
            "https://ecn.t0.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=7505",
            "https://ecn.t1.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=7505",
            "https://ecn.t2.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=7505",
            "https://ecn.t3.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=7505"
          ],
          "tileSize": 256,
          "maxzoom": 18,
          "minzoom": 1,
          "bounds": [
            -180,
            -85.051129,
            180,
            85.051129
          ]
        },
        "layout": {
          "visibility": "none"
        },
        "paint": {
          "raster-opacity": 1,
          "raster-contrast": 0,
          "raster-brightness-min": 0,
          "raster-brightness-max": 1
        }
      })
    },
    displaySatellite (status) {
      this.isBaseMap = status === 'none';
      map.setLayoutProperty('satellite_bing', 'visibility', status)
    },
    initDrawControlShortcuts() {
      this.onKeyUp = function (event) {
        switch (event.key) {
          case '3':
          case 'q':
            if (draw.getMode() !== 'draw_polygon') {
              draw.changeMode('draw_polygon')
            }
            break
          case '4':
          case 'w':
            if (draw.getMode() !== 'draw_rectangle') {
              draw.changeMode('draw_rectangle')
            }
            break
          case '5':
          case 'e':
            if (draw.getMode() !== 'draw_assisted_rectangle') {
              draw.changeMode('draw_assisted_rectangle')
            }
            break
          case '6':
          case 'r':
            if (draw.getMode() !== 'drag_circle') {
              draw.changeMode('drag_circle')
            }
            break
          case 'Delete':
          case 'Backspace':
            if (draw.getSelected()) {
              draw.trash()
            }
            break
          case 'Asc':
            draw.changeMode('simple_select')
            break
          default:
            draw.changeMode('simple_select')
            break
        }
      }.bind(this)

      window.addEventListener('keyup', this.onKeyUp)
    },
    destroyDrawControlShortcuts() {
      if (this.onKeyUp) window.removeEventListener('keyup', this.onKeyUp)
    },
    changeMode(mode) {
      switch (mode) {
        case 'polygon':
          if (draw.getMode() !== 'draw_polygon') {
            draw.changeMode('draw_polygon')
          }
          break
        case 'rectangle':
          if (draw.getMode() !== 'draw_rectangle') {
            draw.changeMode('draw_rectangle')
          }
          break
        case 'assist-rectangle':
          if (draw.getMode() !== 'draw_assisted_rectangle') {
            draw.changeMode('draw_assisted_rectangle')
          }
          break
        case 'drag-circle':
          if (draw.getMode() !== 'drag_circle') {
            draw.changeMode('drag_circle')
          }
          break
        case 'drag-point':
          if (draw.getMode() !== 'draw_point') {
            draw.changeMode('draw_point')
          }
          break
        case 'delete':
          if (draw.getSelected()) {
            draw.trash()
          }
          break
        default:
          break
      }
    },
    onMapClick (e) {
      this.$emit('inspectImage', e.lngLat.lng, e.lngLat.lat)
    },
    updateOpacity (layerId, opacity) {
      map.setPaintProperty(layerId, 'raster-opacity', opacity)
    },
    removeLayer () {
      this.layers.forEach(layer => {
        map.removeLayer(layer.id).removeSource(layer.id)
      })
      this.layersImageMosaic.forEach(layer => {
        map.removeLayer(layer.id).removeSource(layer.id)
      })
      this.layersImageMosaic = []
      this.layers = []
    },
    changeMosaicImage (mosaic) {
      this.layersImageMosaic.forEach(layer => {
        map.removeLayer(layer.id).removeSource(layer.id)
      })
      this.layersImageMosaic = []
      if (mosaic) this.addMosaicImage(this.getMapboxLayer(mosaic))
    },
    getMapboxLayer (image) {
      const source = {
        'type': 'raster',
        'tiles': [image.tile_url],
        'tileSize': 256,
        'maxzoom': 18,
        'minzoom': image.min_zoom || 1,
        'bounds': image.bbox
      }
      const layer = {
        id: image.id,
        'type': 'raster',
        'source': image.id,
        'layout': {
          'visibility': 'visible'
        },
        'paint': {
          'raster-opacity': 1,
          'raster-fade-duration': 0
        }
      }
      return ({source, layer})
    },
    addImagelayer (slope, mosaic) {
      this.removeLayer()
      map.resize()
      this.addNDVILayer(this.getMapboxLayer(slope))
      this.addMosaicImage(this.getMapboxLayer(mosaic))
    },
    addMosaicImage (imageLayer) {
      map.addSource(imageLayer.layer.id, imageLayer.source)
      const layer = imageLayer.layer
      this.layersImageMosaic.push(layer)
      map.addLayer(layer, this.layers[0].id)
    },
    addNDVILayer (imageLayer) {
      imageLayer.layer.paint['raster-opacity'] = 0.5
      map.addSource(imageLayer.layer.id, imageLayer.source)
      const layer = imageLayer.layer
      this.layers.push(layer)
      map.addLayer(layer)
      this.submitZoom(imageLayer.source.bounds)
    },
    zoomToBound(currentImage) {
      this.submitZoom(bbox(currentImage.geometry))
    },
    submitZoom(bbox) {
      map.fitBounds(bbox, {
        'duration': 0,
        'padding': 20
      })
    }
  },
}
</script>

<style scoped>
.map-info {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  z-index: 2;
  background-color: #fcfaf9;
  position: absolute;
  bottom: 10px;
  right: 90px;
  width: 220px;
  height: 30px;
}

#mapSearch {
  width: 100%;
  height: 100%;
}

/deep/
.mapbox-compass {
  border-radius: 50%;
}

/deep/
.mapbox-compass button {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

/deep/
.mapbox-compass svg {
  margin-right: 2px;
  margin-top: 4px;
  width: 25px;
  height: 25px;
}

/deep/
.mapbox-zoom {
  border-radius: 30px;
  box-shadow: none;
}

/deep/
.mapbox-zoom button {
  background-color: white;
  width: 30px;
  height: 30px;
}

/deep/
.mapbox-zoom button:nth-child(1) {
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
}

/deep/
.mapbox-zoom button:nth-child(2) {
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}
/deep/
.mapboxgl-ctrl-scale {
  font-size: 12px;
  text-align: center;
  padding-top: 5px;
  height: 30px;
  width: 75px !important;
  background-color: white;
  border-radius: 30px;

}
</style>
