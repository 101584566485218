<template>
  <v-dialog
      v-model="updateUserDialog"
      max-width="500"
      persistent
  >
    <v-card>
      <v-card-title style="height: 64px; box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22);">
        <span style="font-size: 16px">UPDATE USER</span>
        <v-spacer/>
        <v-btn icon small @click="updateUserDialog = false">
          <img alt="download" :src="require('@/assets/icon/close.svg')">
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-4">
        <v-form @submit="updateUser" onSubmit="return false;" v-model="valid" ref="formUpdate">
          <v-text-field outlined dense v-model="currentUser.name" label="User name" :rules="[rules.requiredUserName]"></v-text-field>
          <v-text-field outlined dense v-model="currentUser.email" label="Email" :rules="[rules.requiredEmail]"></v-text-field>
          <v-text-field  outlined dense v-model="currentUser.password" label="Password"
                         :type="isShowPassword ? 'text' : 'password'"
                        :append-icon="isShowPassword ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="isShowPassword = !isShowPassword">
          </v-text-field>
          <v-select outlined dense v-model="currentUser.role" :items="roles" label="Role" :rules="[rules.role]" item-text="name" return-object></v-select>
          <v-checkbox
              class="mt-0"
              hide-details
              color="#893FF2"
              v-model="currentUser.active"
              label="Active status"
          ></v-checkbox>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn width="100" color="#893FF2" @click="updateUser" dark>Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  updateUser,
  getRole
} from "@/backend";
export default {
  name: "PopupUpdateUser",
  data () {
    return {
      id: undefined,
      loading: false,
      updateUserDialog: false,
      valid: false,
      isShowPassword: false,
      currentUser: {},
      roles: [],
      rules: {
        requiredUserName: value => (!!value && !!value.trim()) || 'User name is required',
        requiredPassword: value => (!!value && !!value.trim()) || 'Password is invalid',
        requiredEmail: value => (!!value && !!value.trim()) || 'Email is required',
        role: value => !!value || 'Role is required'
      }
    }
  },
  mounted() {
    this.getRole()
  },
  watch: {
    updateUserDialog (val) {
      if (!val) {
        this.currentUser = {}
        if (this.$refs.formUpdate) this.$refs.formUpdate.resetValidation()
      }
    }
  },
  methods: {
    openDialog (user) {
      this.currentUser = JSON.parse(JSON.stringify(user))
      this.id = this.currentUser.id
      this.currentUser.role = this.roles.find(role => role.code === this.currentUser.role)
      delete this.currentUser.id
      delete this.currentUser.created_at
      this.updateUserDialog = true
    },
    async getRole () {
      this.loading = true
      try {
        const res = await getRole()
        this.roles = res.data.data
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', e.message)
      } finally {
        this.loading = false
      }
    },
    async updateUser () {
      this.$refs.formUpdate.validate()
      if (this.valid) {
        this.loading = true
        try {
          if (this.currentUser.password) this.currentUser.password_confirmation = this.currentUser.password
          this.currentUser.role_id = this.currentUser.role.id
          const res = await updateUser({
            id: this.id,
            data: this.currentUser
          })
          this.$store.commit('message/SHOW_SUCCESS', 'Success')
          this.$emit('getUser')
          this.updateUserDialog = false
        } catch (e) {
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
