import { render, staticRenderFns } from "./FullScreenSquare.vue?vue&type=template&id=370ee062&scoped=true&"
import script from "./FullScreenSquare.vue?vue&type=script&lang=js&"
export * from "./FullScreenSquare.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "370ee062",
  null
  
)

export default component.exports