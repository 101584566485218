<template>
  <v-card flat style="height: calc(100vh - 120px)">
    <v-card-text class="fill-height" style="padding: 20px 42px 20px 42px">
      <v-layout class="fill-height">
        <div class="mr-6" style="width: 50%; height: 100%; border: 1px solid rgba(137, 63, 242, 0.2); box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22)">
          <div style="height: 55px; border: 1px solid rgba(137, 63, 242, 0.2); box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22); background-color: #F9F9F9" class="px-6">
            <v-layout align-center class="fill-height">
              <span style="color: black; font-size: 0.8vw">Overview of Changes view</span>
              <div style="width: 15vw">
                <v-select
                    @change="getData"
                    height="30"
                    style="min-height: 0"
                    class="ml-2 filter-select"
                    item-text="name"
                    item-value="code"
                    hide-details
                    outlined
                    dense
                    v-model="type"
                    :items="types"
                ></v-select>
              </div>
              <v-spacer/>
              <span style="color: black; font-size: 0.8vw">Recorded for</span>
              <div style="width: 9vw" class="ml-2">
              <MonthPicker label="Recorded for" v-model="recordedFor" @input="getData"/>
              </div>
            </v-layout>
          </div>
          <div style="height: calc(100% - 55px);" class="pa-5 overflow-y-auto">
            <div style="width: 100%; height: 100px; border-bottom: 3px solid black; position: relative">
              <v-layout align-end
                        style="height: 100px; width: 30%; background-color: white; position: absolute; bottom: -40px; left: 0">
                <span style="line-height: 100px; font-size: 2.6vw; font-weight: bold; color: black" >{{data ? this.transformNumber(data.green_cover_area) : ''}}</span>
              </v-layout>
              <v-layout align-start
                        style="height: 100px; width: 30%; background-color: white; position: absolute; bottom: 0; left: 30%">
                <span style="line-height: 100px; font-size: 25px; font-weight: bold; color: black">km<sup>2</sup></span>
              </v-layout>
              <v-layout
                  align-end
                  class="pl-5"
                  style="height: 100px; width: 33%; background-color: white; position: absolute; bottom: -10px; right: 0">
                <span style="line-height: 35px; font-size: 1.4vw; font-weight: bold; font-style: italic; color: black">Green Cover Area Visualisation</span>
              </v-layout>
            </div>
            <div style="margin-top: 56px; width: 100%; height: 150px; border-top: 3px solid black; border-left: 3px solid black; border-bottom: 3px solid black; position: relative">
              <v-layout align-end justify-center style="height: 32px; width: 210px; background-color: white; position: absolute; top: -15px; left: 30px">
                <span style="line-height: 38px; font-size: 17px; font-style: italic; font-weight: bold; color: black">Area change</span>
              </v-layout>
              <v-layout
                  align-center
                  class="px-5"
                  style="height: 70px; width: calc(100% - 180px); background-color: white; position: absolute; bottom: -35px; left: 180px">
                <span style="font-size: 1.2vw; font-weight: bold; color: black">Canopy Cover Statistic</span>
                <v-spacer/>
                <span style="line-height: 70px; font-size: 4vw; font-weight: bold; color: black" v-if="data">{{data.canopy_cover_index}}</span>
              </v-layout>
              <v-layout
                  v-if="data"
                  v-show="data && parseFloat(data.area_change) >= 0"
                  align-start
                  class="pl-5"
                  style="height: 50px; width: 200px; background-color: white; position: absolute; top: -25px; right: 0">
                  <span style="line-height: 50px; font-size: 40px; font-weight: bold; font-style: italic; color: black">
                    {{Math.abs(parseFloat(data.area_change)).toFixed(3)}}%
                </span>
                <v-icon v-if="parseFloat(data.area_change) < 0" size="40" color="green">mdi-arrow-down-bold</v-icon>
                <v-icon v-else size="40" color="green">mdi-arrow-up-bold</v-icon>
              </v-layout>
            </div>
            <div style="margin-top: 100px; width: 100%; height: 300px; position: relative">
              <v-layout class="fill-height" column>
                <v-layout style="width: 100%; height: 100px" column>
                  <span style="color: #000000; font-size: 20px; font-style: italic">Data Image Statistic</span>
                  <span class="mt-8" style="color: #000000; font-size: 2vw; font-weight: 500;">Number of image data</span>
                </v-layout>
                <v-layout style="width: 100%; height: calc(100% - 150px)">
                  <canvas style="height: 100%; width: 100%" id="imageDataChart"></canvas>
                </v-layout>
              </v-layout>
            </div>
          </div>
        </div>
        <div class="ml-6" style="height: 100%; width: 50%">
          <v-layout column class="fill-height">
            <v-layout class="mb-5" fill-height column style="box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22); width: 100%; flex-grow: 1; border: 1px solid rgba(137, 63, 242, 0.2)">
              <div style="height: 55px; border-bottom: 1px solid rgba(137, 63, 242, 0.2); box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22);" class="px-6">
                <span style="line-height: 55px; color: black; font-size: 16px">Recorded trends of Green Cover Change in a Year</span>
              </div>
              <v-layout class="fill-height">
                <div style="height: 100%; width: 75%; border-right: 1px solid rgba(137, 63, 242, 0.2); box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22); position: relative" class="pa-2">
                  <canvas style="height: 100%; width: 100%" id="myChart"></canvas>
                </div>
                <div style="height: 100%; width: 25%;">
                  <v-list dense class="py-0">
                    <v-list-item-group
                        @change="updateChartData"
                        v-model="chartType"
                        color="#d42ff5"
                    >
                      <v-list-item
                          v-for="(item, i) in types"
                          :key="i"
                          :value="item.code"
                      >
                        <v-list-item-content>
                          <v-list-item-title v-text="item.name"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </div>
              </v-layout>
            </v-layout>
            <v-layout class="mt-5" fill-height column style="box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22); flex-grow: 1; border: 1px solid rgba(137, 63, 242, 0.2)">
              <div style="height: 55px; border-bottom: 1px solid lightgray" class="px-6">
                <span style="line-height: 55px; color: black; font-size: 16px">Map view</span>
              </div>
              <div style="width: 100%; height: 100%;">
                <BaseMap ref="mapView"/>
              </div>
            </v-layout>
          </v-layout>
        </div>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import BaseMap from "@/components/home/overview/BaseMap"
import MonthPicker from "@/components/MonthPicker"
import Chart from 'chart.js/auto'
import {getDataDashBoard} from "@/backend";
import MonthTransform from "@/ultis/converMonth";
import numberFormat from "@/ultis/comma";
let date = new Date()
export default {
  name: "Overview",
  components: {MonthPicker, BaseMap },
  data () {
    return {
      imageDataChart: undefined,
      myChart: undefined,
      recordedFor: '2021-04',
      data: {},
      imageData: [],
      imageDataLabel: [],
      chartData: {},
      chartType: 'overall_green_cover',
      type: 'overall_green_cover',
      types: [{
        name: 'OVERALL GREEN COVER',
        code: 'overall_green_cover',
      }]
    }
  },
  mounted() {
    this.getData()
  },
  watch: {
  },
  methods: {
    async getData () {
      try {
        let month = new Date(this.recordedFor).getMonth() + 1
        if (new Date(this.recordedFor).getMonth() + 1 < 10)  month = `0${new Date(this.recordedFor).getMonth() + 1}`
        const res = await getDataDashBoard({
          month: month,
          year: new Date(this.recordedFor).getFullYear(),
          source: 'Sentinel',
          overview_type: this.type
        })

        this.imageDataLabel = res.data.data.image_source.src
        this.imageData = res.data.data.image_source.amount
        this.chartData = res.data.data.green_cover_change_in_year
        this.chartData.overall_green_cover.labels = this.convertMonth(this.chartData.overall_green_cover.labels)
        if (this.myChart) this.updateChartData
        else this.displayChart()
        if (!this.imageDataChart) this.displayImageDataChart()
        else this.updateImageDataChart()
        if (res.data.data.image_ndvi) this.$refs.mapView.addNDVILayer(res.data.data.image_ndvi)
        this.data = res.data.data.image_result
      } catch (e) {
        this.$refs.mapView.removeLayer()
        console.log(e.message)
      } finally {
        this.loading = false
      }
    },
    convertMonth (monthArray) {
      return MonthTransform.transformMonthTextArray(monthArray)
    },
    updateChartData () {
      this.myChart.data.datasets.forEach((dataset) => {
        if (dataset.label === 'CHANGE') dataset.data = this.chartData[this.chartType].change
        if (dataset.label === 'MONTHLY') dataset.data = this.chartData[this.chartType].monthly
      })
      this.myChart.update()
    },
    displayImageDataChart () {
      let ctx = document.getElementById('imageDataChart');
      const data = {
        labels: this.imageDataLabel,
        datasets: [{
          label: 'Image',
          data: this.imageData,
          borderColor: 'rgb(255, 99, 132)',
          barPercentage: 0.3,
          backgroundColor: '#9FD2C4'
        }]
      }
      this.imageDataChart = new Chart(ctx, {
        type: 'bar',
        data: data,
        options: {
          plugins: {
            legend: { display: false }
          }
        }
      })
    },
    displayChart () {
      let ctx = document.getElementById('myChart');
      const data = {
        labels: this.chartData[this.chartType].labels,
        datasets: [{
          type: 'line',
          label: 'CHANGE',
          data: this.chartData[this.chartType].change,
          fill: false,
          borderColor: 'rgb(54, 162, 235)'
        }, {
          type: 'bar',
          label: 'MONTHLY',
          data: this.chartData[this.chartType].monthly,
          barPercentage: 0.5,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: '#9FD2C4'
        }]
      };
      this.myChart = new Chart(ctx, {
        type: 'bar',
        data: data,
        options: {
          legend: {
            display: false
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.yLabel;
              }
            }
          }
        }
      })
    },
    updateImageDataChart () {
      this.imageDataChart.data.datasets.forEach((dataset) => {
        dataset.labels = this.chartData[this.chartType].labels
        dataset.data = this.imageData
      })
      this.imageDataChart.update()
    },
    transformNumber (number) {
      return numberFormat.numberWithCommas(number)
    }
  }
}
</script>

<style scoped>
/deep/
.month-picker .v-input__slot {
  min-height: 0 !important;
}
/deep/
.month-picker .v-input__prepend-inner {
  margin-top: 4px !important;
}
/deep/
.filter-select .v-input__slot {
  min-height: 0 !important;
}
/deep/
.filter-select .v-input__append-inner {
  margin-top: 2px !important;
}
</style>
