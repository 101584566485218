<template>
  <v-dialog
      v-model="createUserDialog"
      max-width="500"
      persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn outlined v-bind="attrs" v-on="on" width="200" min-height="0" height="31" color="#893FF2" style="background-color: white; box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);">
        <span>Create new user</span>
      </v-btn>
    </template>

    <v-card>
      <v-card-title style="height: 64px; box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22);">
        <span style="font-size: 16px">CREATE NEW USER</span>
        <v-spacer/>
        <v-btn icon small @click="createUserDialog = false">
          <img alt="download" :src="require('@/assets/icon/close.svg')">
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-4">
        <v-form @submit="createUser" onSubmit="return false;" v-model="valid" ref="formCreate">
          <v-text-field outlined dense v-model="userName" label="User name" :rules="[rules.requiredUserName]"></v-text-field>
          <v-text-field outlined dense v-model="email" label="Email" :rules="[rules.requiredEmail]"></v-text-field>
          <v-text-field  outlined dense v-model="password" label="Password" :rules="[rules.requiredPassword]"
                         :type="isShowPassword ? 'text' : 'password'"
                        :append-icon="isShowPassword ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="isShowPassword = !isShowPassword">
          </v-text-field>
          <v-text-field outlined dense v-model="confirmPassword" label="Confirm Password" :rules="[rules.requiredConfirmPassword]"
                        :type="isShowPassword ? 'text' : 'password'"
                        :disabled="!this.password"
                        :append-icon="isShowPassword ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="isShowPassword = !isShowPassword">
          </v-text-field>
          <v-select outlined dense v-model="role" :items="roles" label="Role" :rules="[rules.role]" item-text="name" return-object></v-select>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn width="100" color="#893FF2" @click="createUser" dark>Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  createUser,
  getRole
} from "@/backend";
export default {
  name: "PopupCreateUser",
  data () {
    return {
      loading: false,
      createUserDialog: false,
      valid: false,
      isShowPassword: false,
      userName: undefined,
      email: undefined,
      password: undefined,
      confirmPassword: undefined,
      role: undefined,
      roles: [],
      rules: {
        requiredUserName: value => (!!value && !!value.trim()) || 'User name is required',
        requiredPassword: value => (!!value && !!value.trim()) || 'Password is invalid',
        requiredEmail: value => (!!value && !!value.trim()) || 'Email is required',
        requiredConfirmPassword: value => (!!value && !!value.trim() && value.trim() === this.password.trim()) || 'Password is invalid',
        role: value => !!value || 'Role is required'
      }
    }
  },
  mounted() {
    this.getRole()
  },
  watch: {
    createUserDialog (val) {
      if (val) {
        this.userName = undefined
        this.email = undefined
        this.password = undefined
        this.confirmPassword = undefined
        this.role = undefined
        if (this.$refs.formCreate) this.$refs.formCreate.resetValidation()
      }
    }
  },
  methods: {
    async getRole () {
      this.loading = true
      try {
        const res = await getRole()
        this.roles = res.data.data
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', e.message)
      } finally {
        this.loading = false
      }
    },
    async createUser () {
      this.$refs.formCreate.validate()
      if (this.valid) {
        this.loading = true
        try {
          const res = await createUser({
            "name": this.userName,
            "email": this.email,
            "password": this.password,
            "password_confirmation": this.confirmPassword,
            "role_id": this.role.id
          })
          this.$store.commit('message/SHOW_SUCCESS', 'Success')
          this.$emit('getUser')
          this.createUserDialog = false
        } catch (e) {
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
