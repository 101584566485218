<template>
  <v-layout>
    <v-layout style="width: 460px; height: calc(100vh - 400px);">
      <v-card width="100%">
        <v-card-title class="py-0 px-0" style="height: 40px;">
          <v-layout align-center style="height: 40px" class="d-flex flex-row justify-space-between">
            <!-- <v-icon class="mr-2" color="#893FF2">mdi-layers</v-icon> -->
            <p style="font-size: 18px; color: #893FF2; text-transform: uppercase;" class="mb-0 pl-2">Layer Manager</p>
              <v-btn @click="removeAllLayer()" class="px-1" style="color: #893ff2; border: solid 1px #893ff2; border-radius: 5px; background: white;">
                <!-- <v-icon>mdi-delete-outline</v-icon> -->Clear All
              </v-btn>
            <!-- <v-spacer/> -->
            <!-- <v-btn @click="() => $emit('closeLayerControl')" icon style="border: 1px solid #893FF2">Hide Layer Tab <v-icon>mdi-pan-right</v-icon></v-btn> -->
              <v-btn @click="() => $emit('closeLayerControl')" style="color: #893ff2; border: solid 1px #893ff2; border-radius: 5px; background: white;" class="px-1">
                  Hide Layer Tab <v-icon>mdi-pan-right</v-icon>
              </v-btn>
          </v-layout>
        </v-card-title>
        <v-divider/>
        <v-card-text style="height: calc(100% - 90px)" class="py-1 overflow-y-auto px-5">
          <draggable
              v-bind="dragOptions"
              v-model="addedLayers"
              handle=".handle"
              @end="moveLayer"
              @start="checkValid"
              group="row"
          >
            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
              <v-layout v-for="(item, index) in addedLayers" :key="'a' + index" class="my-1">
                <v-layout class="fill-height" style="border-bottom: 1px lightgray solid">
                  <div style="height: 50px; width: 10px;" :style="{'background-color': item.color}" class="handle"></div>
                  <v-layout column class="pl-2">
                    <div style="height: 20px; font-weight: 500" :style="{'color': item.color}">
                      <v-layout class="fill-height">
                        
                        <div style="width: 250px; height:40px; line-height:1.8; font-size:9.5px" class="text-no-wrap" :title="item.name" v-if="item.data_type === 'raster_image'">
                              <span color="red">{{item.AoiName}}</span> <br>{{item.name}}
                        </div>
                        <div style="width: 250px;" class="text-truncate" :title="item.name" v-if="item.data_type === 'vector'">{{item.name}}</div>
                        <v-spacer/>
                        <v-layout class="fill-height" style=" width: 180px" justify-end align-center>
                          <v-btn class="mx-1" x-small icon @click="() => $emit('zoomToImage', item)"><v-icon>mdi-crosshairs-gps</v-icon></v-btn>
                          <v-btn v-if="mapStatus === 'overlay' || mapStatus === 'time'" class="mx-1" x-small icon @click="() => $emit('changeViewImageRightMap', item)">
                            <v-icon v-if="item.layoutRight">mdi-eye</v-icon>
                            <v-icon v-else>mdi-eye-off</v-icon>
                          </v-btn>
                          <v-layout v-if="mapStatus === 'slide' || mapStatus === 'sync'">
                            <v-btn @click="() => $emit('changeViewImageLeftMap', item)" :style="{'background-color': item.layoutLeft ? '#e4d7ff' : ''}" text x-small><span style="font-size: 12px">L</span></v-btn>
                            <v-btn @click="() => $emit('changeViewImageRightMap', item)" :style="{'background-color': item.layoutRight ? '#e4d7ff' : ''}" text x-small><span style="font-size: 12px">R</span></v-btn>
                          </v-layout>
                          <v-btn icon x-small @click="removeLayer(item)"><v-icon>mdi-delete-outline</v-icon></v-btn>
                          <v-btn :disabled="!item.list_values || !item.list_values.length" class="mx-1" small icon @click="item.displayLabel = !item.displayLabel">
                            <v-icon v-if="item.displayLabel">mdi-menu-up</v-icon>
                            <v-icon v-else>mdi-menu-down</v-icon>
                          </v-btn>
                        </v-layout>
                      </v-layout>
                    </div>
                    <div style="height: 25px">
                      <v-layout class="fill-height">
                        <v-layout class="pr-10">
                          <v-slider
                              @change="updateOpacity(item)"
                              :max="1"
                              :min="0"
                              v-model="item.opacity"
                              step="0.1"
                              hide-details
                              track-color="#893FF2"
                          ></v-slider>
                        </v-layout>
                        <div style="width: 60px; height: 20px; border: 1px #893FF2 dashed; margin-top: 4px; padding-top: 2px">
                          <v-layout class="fill-height" :style="{'color': item.color}" align-center justify-center>
                            {{item.opacity * 100}} %
                          </v-layout>
                        </div>
                      </v-layout>
                    </div>
                    <v-layout class="mt-1" v-if="item.displayLabel && item.list_values && item.list_values.length" style="border-top: 1px solid lightgray">
                      <v-list width="100%" dense color="#faf9fb">
                        <v-list-item style="height: 30px; min-height: 0" v-for="(value, idx) in item.list_values" :key="'a' + idx">
                          <span :style="{'color': value.color}">{{value.name}}</span>
                          <v-spacer/>
                          <v-list-item-action>
                            <v-checkbox
                                @change="moveLayer()"
                                v-model="value.display"
                                :color="value.color"
                                hide-details
                            ></v-checkbox>
                            <!--                          <div style="width: 20px; height: 20px; border: 1px solid white; border-radius: 2px" :style="{'background-color': value.color}"></div>-->
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-layout>
                  </v-layout>
                </v-layout>
              </v-layout>
            </transition-group>
          </draggable>
        </v-card-text>
        <v-divider/>
        <v-card-actions style="height: 40px;" class="py-0">
          <v-switch
              dense
              hide-details
              color="#893FF2"
              class="switch-label mt-2"
              @change="() => $emit('toggleLabel', isDisplayLabel)"
              v-model="isDisplayLabel"
              label="Street layer"
          ></v-switch>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-layout>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  components: { draggable },
  name: "LayerControl",
  data() {
    return {
      drag: false,
      isDisplayLabel: false,
      radioGroup: undefined,
    }
  },
  props: {
    mapStatus: {},
    layers: {},
    currentAOI: ''
  },
  computed: {
    addedLayers: {
      get () {
        return this.layers
      },
      set (value) {
        this.$emit('update:layers', value)
      }
    },
    dragOptions () {
      return {
        animation: 300,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  watch: {
  },
  methods: {
    updateOpacity (currentLayer) {
      let layer = this.addedLayers.find(layer => layer.id === currentLayer.id)
      layer.opacity = currentLayer.opacity
      this.$emit('updateOpacity', currentLayer, currentLayer.opacity)
    },
    checkValid (e) {
      this.drag = true
      this.currentDrag = this.addedLayers[e.oldIndex]
    },
    removeAllLayer () {
      const idx = this.addedLayers.forEach(layer => {
     
        layer.layoutLeft = false
        layer.layoutRight = false
        
        this.$emit('removeLayer', layer)
      })
	  
	  this.addedLayers = []
    },	
    removeLayer (layer) {
      const idx = this.addedLayers.findIndex(val => val.id === layer.id)
      if (idx >= 0) {
        layer.layoutLeft = false
        layer.layoutRight = false
        this.addedLayers.splice(idx, 1)
        this.$emit('removeLayer', layer)
      }
    },
    moveLayer (e) {
      this.currentDrag = undefined
      let tmp = []
      this.addedLayers.forEach(val => {
        tmp.push(val)
      })
      this.drag = false
      this.$emit('newMoveLayer', tmp)
    }
  }
}
</script>

<style scoped>
/deep/
.switch-label label {
  color: #893FF2;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
</style>
