<template>
  <svg width="18" height="18" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.9957" y="1.90928" width="9.00769" height="9.12966" :fill="$attrs.color" :stroke="$attrs.color" stroke-width="0.3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999609 0.752197H0.849609V0.902197V3.47392C0.849609 3.55676 0.916767 3.62392 0.999609 3.62392C1.08245 3.62392 1.14961 3.55676 1.14961 3.47392V1.0522H3.11499C3.19784 1.0522 3.26499 0.98504 3.26499 0.902197C3.26499 0.819355 3.19784 0.752197 3.11499 0.752197H0.999609ZM0.999609 12.1964H0.849609V12.0464V9.47464C0.849609 9.39179 0.916767 9.32464 0.999609 9.32464C1.08245 9.32464 1.14961 9.39179 1.14961 9.47464V11.8964H3.11499C3.19784 11.8964 3.26499 11.9635 3.26499 12.0464C3.26499 12.1292 3.19784 12.1964 3.11499 12.1964H0.999609ZM12.1496 0.752197V0.902197V3.0453C12.1496 3.12814 12.0825 3.1953 11.9996 3.1953C11.9168 3.1953 11.8496 3.12814 11.8496 3.0453L11.8496 1.0522L9.46115 1.0522C9.3783 1.0522 9.31115 0.98504 9.31115 0.902197C9.31115 0.819355 9.37831 0.752197 9.46115 0.752197H11.9996H12.1496ZM12.1496 12.0464V12.1964H11.9996L9.46115 12.1964C9.37831 12.1964 9.31115 12.1292 9.31115 12.0464C9.31115 11.9635 9.3783 11.8964 9.46115 11.8964H11.8496L11.8496 9.90326C11.8496 9.82041 11.9168 9.75326 11.9996 9.75326C12.0825 9.75326 12.1496 9.82041 12.1496 9.90326V12.0464Z" :fill="$attrs.color"/>
  </svg>
</template>

<script>
export default {
  name: "FullScreenSquare"
}
</script>

<style scoped>

</style>
