<template>
  <div class="table-wrapper">
    <h3 id="left_label" style="background-color:white;position:absolute;top:60px;right:50%;">LEFT PERIOD</h3>
	<h3 id="right_label" style="background-color:white;position:absolute;top:60px;left:50.2%;">RIGHT PERIOD</h3>
  <!-- <br> -->
  <v-row class="headerBorder justify-space-between ma-0" >
    
    <v-col
      cols="1"
      class="ml-11 py-0 mx-0"
    >
        <p style="color: #893FF2; font-weight: 600;" class="text-h5 my-auto">DATA LIBRARY</p>
    </v-col>
 
 
	
    <v-col
      cols="8"
      class="py-0 mx-0 d-flex flex-row my-auto justify-center"
    >
    <!-- border: solid 2px rgba(137, 63, 242, 0.2); border-radius: 7px; border-sizing: border-box; -->
		  <span style="margin-left:20px;font-weight: 700;" class="viewChanges align-self-center">View changes between</span>
        <div style="margin:10px 0; border: solid 2px rgba(137, 63, 242, 0.2); border-radius: 7px; border-sizing: border-box; display:flex; width: 800px;" class="align-content-center justify-space-between" >
                  <div style="width: 300px;" class="pl-3">
                    <v-autocomplete
                        flat
                        style="border:1px"
                        @change="changeYYMM"
                        v-model="yymm_item"
                        :items="yymm_lst"
                        dense
						            color="grey"
                        item-text="yymm_label"
                        return-object
                        class="align-self-center chgDatePicker"
                        prepend-icon="mdi-calendar-month"
                        hide-details
                        />
                  </div>
  
                   <!-- <span style="margin-left:20px;font-weight: 700;">AOI</span> -->
                   <searchBox @aoiSelected="changeAOI" id="chgTable" class="mx-4 searchBoxPosition"></searchBox>
                  <!-- <div style="width: 300px;" class="pl-3">
                    <v-autocomplete
                        flat
                        style="border:1px"
                        @change="changeAOI"
                        v-model="aoi"
                        :items="aois"
                        dense
						            color="grey"
                        item-text="properties.NAME"
                        return-object/>
                  </div> -->
				  
	            <!-- <span style="margin-left:20px;font-weight: 700;">Change Type</span> -->
                <v-menu
                  offset-x
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-bind="category"
                      v-on="on"
                      style="align-self: center; background: rgba(159, 210, 196, 0.3); border: 2px solid #4DA76B; box-sizing: border-box; border-radius: 7px; color: #4DA76B;"
                    >
                      {{chgBtn}} <v-icon>mdi-menu-right-outline</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="(item, index) in typ_lst"
                      :key="index"
                      style="background: rgba(195, 179, 218, 0.23);"
                    >
                      <v-list-item-title :style="{color: item.color, border: 'solid 1px ' + item.color}" style="padding: 5px; border-radius: 5px; cursor: pointer;" @click="cateType(item)">{{ item.label }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
        </div>
                  <!-- <div style="width: 200px;" class="pl-3">
                    <v-autocomplete
                        flat
                        style="border:1px"
                        @change="changeTyp"
                        v-model="typ_item"
                        :items="typ_lst"
                        dense
						            color="grey"
                        item-text="label"
                        return-object/>
                  </div> -->
                  <!-- :disabled="!yymm_item && !aoi"  -->

        <v-btn  @click="shp()" style="color: #893ff2; border: solid 1px #893ff2; border-radius: 5px; background-color: #e4d7ff; align-items: center; align-self: center;" class="mx-1">SHP</v-btn>   
        <v-btn  @click="csv()" style="color: #893ff2; border: solid 1px #893ff2; border-radius: 5px; background-color: #e4d7ff; align-items: center; align-self: center;" class="mx-1">CSV</v-btn>    
    </v-col>             			  

      <v-col
        cols="2"
        class="py-0 mx-0 justify-end text-right"
      >
        <v-btn v-if="tblShow" @click="hideChangeDetection" outlined style="margin-left: 20px; background-color: white;" color="#893FF2" height="30" width="190" min-width="0">
          <span style="">
            <v-icon color="#893FF2">mdi-chevron-double-down</v-icon>
              Change Detection
          </span>
        </v-btn>	

       <v-btn v-if="!tblShow" @click="showChangeDetection" outlined style="margin-left: 20px; background-color: white;" color="#893FF2" height="30" width="190" min-width="0">
          <span style="">
            <v-icon color="#893FF2">mdi-chevron-double-up</v-icon>
              Change Detection
          </span>
        </v-btn>			
      </v-col>	 
	</v-row>
	
	
    <div class="table-content" style="padding: 0 48px; background: #F9F9F9;">

 <v-client-table ref="tbl" :data="detections_filtered" :columns="columns" :options="options" @select="select_row" @sorted="sorted" >
  
    <!--
  <i slot="preview" slot-scope="props" aria-hidden="true" @click="zoom(props.row)" class="v-icon notranslate mdi mdi-crosshairs-gps theme--light"></i>
	-->
      <div slot="preview" slot-scope="props">
        <!-- <v-icon :id="props.row.id" @click="preview_chg(props.row)">mdi-eye</v-icon> -->
        <span style="background: linear-gradient(180deg, #EBDDFF 0%, #D5C0F3 100%); box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.13), inset 0px 0px 7px rgba(137, 63, 242, 0.2); border-radius: 7px; padding: 5px 5px;">
          <input type="checkbox" :id="props.row.id" @change="preview_chg(props.row)">
          <span :id="'span-' + props.row.id"  style="margin-left:10px;"  @click="span_preview_click(props.row)">
            
            Preview
          </span>
        </span>
      </div>
          
      <span slot="aoi" slot-scope="props" v-text="props.row.aoi" class="colAOI" style="border: solid 1px #893ff2; background: #F3F3FF; border-radius: 4.4px; padding:0.5px 8px;"></span>
      <span slot="prov" slot-scope="props" v-text="props.row.prov" class="colProv" style="border: solid 1px #3E66FB; background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #3E66FB; border-radius: 4.4px; padding:0.5px 8px;"></span>
      <span slot="mine" slot-scope="props" v-text="props.row.mine" class="colMine" style="background: #FFF4FA; border: 1px solid #F178B6; box-sizing: border-box; border-radius: 4.4px; padding:0.5px 8px;"></span>

  </v-client-table>



    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import {mapState} from "@/store/ults";
import { cloneDeep } from 'lodash'
import EventBus from '@/event-bus'
import {ClientTable, Event} from 'vue-tables-2-premium'
import MonthPicker from "@/components/MonthPicker"
import search from "@/components/home/aoiMenu";
import {getDetections_Count, getDetections} from "@/backend"
import {getDetectionsx_Count, getDetectionsx} from "@/backend"
import {getImageries} from "@/backend";
import area from'@turf/area'
import * as turf from '@turf/turf'
import aois_info from '@/assets/aois-overview.json'
import FileSaver from 'file-saver'
import axios from 'axios'

Vue.use(ClientTable)

export default {
 components: {
 MonthPicker,
   'searchBox' : search, 
 ClientTable
  },
  props: {

  },
  data() {
    return {
	tblShow : true,
	sort_option : {},
	loaded_previews : [],
	tbl : {},
	images : {},
	last_preview_id : undefined,
	preview_mosaic1_id :'',
	preview_mosaic2_id :'',
    mmm_lst :  ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    yymm_lst : [{yymm:'2021-10'},{yymm:'2021-11'}],
    typ_lst : [{typ: 'gn', label : 'Green To Non Green', color:'#4DA76B', bg: '6ed4a899'} , {typ: 'ng', label : 'Non Green To Green', color: '#DA8300' ,bg: 'da8300c4'}],
    typ_item : {typ: 'gn', label : 'Green To Non Green'},
    loading: false,
		yymm_item : {},
		detections : [],
		detections_filtered : [],
	    yymm : '',
		aois_hash : {},
		aois_name : {},
		aois_info : [],
		aoi : {},
    category: {},
    columns: ['preview','aoi','prov', 'mine' , 'aoi_area','area','pchg','id1'],
    chgBtn: 'GREEN TO NON GREEN',
   
    options: {
      headings: {
        id1 : 'Change ID',
        aoi : 'AOI Name',
        prov : 'Province',
        mine : 'Mine Type',
        aoi_area : 'Area (sqkm)',
        area : 'Change Area (Ha)',
        pchg :'% of Area Change'
      },
     // editableColumns:['name'],
      sortable: ['aoi','prov', 'mine' , 'aoi_area','area','pchg','id1'],
      filterable: false,
	   selectable:{
    mode: 'single', // or 'multiple'
    only: function(row) {
       return true // any condition
    },
    selectAllMode: 'all', // or 'page'
    programmatic: true,
	perPage : 500,
	addSortedClassToCells: true,
pagination:{
        virtual: false
    }
  }
    },

      filterWord: '',
 

    }
  },
 
mounted() {

     this.tbl = this.$refs.tbl

setTimeout(this.set_state,0)
 	 
       EventBus.$on('featureSel', (id) => {
      this.onFeatureSel(id)
    })
	
	      EventBus.$on('featureUnSel', (id) => {
      this.onFeatureUnSel(id)
    })
 
 this.yymm_lst.reverse()
 
 this.yymm_lst.forEach((m) => {
 
 m.yymm_label = this.period_mmmyy(m.yymm)
 
 })
 
 console.log(this.yymm_lst)
  
 this.aois_info = []
 aois_info.data.forEach(a => {
 
  var polygon = turf.polygon(a.geometry.features[0].geometry.coordinates)
	
 a.area = Number((area(polygon)/1000000.0))
 
 var x1 = 999999999
var x2 = -999999999
var y1 = 999999999
var y2 = -999999999
var c = a.geometry.features[0].geometry.coordinates[0]
//console.log('coordinate',c)
for (var i = 0; i < c.length; i++ ) {
	
	if (c[i][0] < x1)
		x1 = c[i][0]
	if (c[i][0] > x2)
		x2 = c[i][0]	
	if (c[i][1] < y1)
		y1 = c[i][1]
	if (c[i][1] > y2)
		y2 = c[i][1]		
}

 a.bbox =  [
                    x1,
                    y1,
                    x2,
                    y2
                ]
 this.aois_name[a.id] = a.name				
 this.aois_hash[a.name] = a
 this.aois_info.push(a)
 }
 )
 
console.log('AOIS_HASH',this.aois_hash)

window.left_label = document.getElementById('left_label')
window.right_label = document.getElementById('right_label')

setTimeout(this.getImages , 200)

return
var thead = document.getElementsByTagName("thead")
var br = document.createElement("br");
br.style.position = 'sticky'
thead[0].parentNode.insertBefore(br, thead[0].nextSibling)

},
  computed: {
  },
  watch: {

  },
   methods: {
    async getImages () {
      try {
	  
 const res = await getImageries()
	   
	
        this.allImage = []
		res.data.data.forEach(i => {
		
		if (i.type == 'mosaic')
		{
		var img = i.year + '-' + i.month + '-' + this.aois_name[i.aoi_id]
		img = img.replace('2021-07','2021-09').replace('2021-08','2021-09')
		
		this.images[img] = i.tile_url
		}
		
		})
      } catch (e) {
        console.log(e)
      } finally {
       // this.loading = false
      }
    },   
      shp(){
var cql_filter = "after_del_mth='" + this.yymm_item.yymm + "' and aoi = '" + this.aoi.name + "' and typ='" + this.typ_item.typ + "'"

var filename = this.aoi.name + ' ' + this.yymm_item.yymm_label + ' ' + (this.typ_item.typ == 'gn' ? 'Green To Non Green' : 'Non Green To Green')
   
   console.log('cql',cql_filter)
  
var url = "http://119.75.24.70:8080/geoserver/wfs?service=wfs&version=1.0.0&request=getfeature&typename=timah:detect&PROPERTYNAME=aoi,before_del_mth,after_del_mth,area,change_type,qa_id,ullr&CQL_FILTER=" + cql_filter + "&outputFormat=shape-zip&format_options=filename:" + filename + ".zip"

window.open(url)

   },
   csv(){
   var csv = ''
   
  for (var j = 0; j < this.options.sortable.length - 1; j++) {
  csv =  csv + (j > 0 ? ',':'')  + this.options.headings[this.options.sortable[j]]
  }
   
   csv = csv + '\n'
 for (var i = 0; i < this.detections_filtered.length; i++) {
    
  for (var j = 0; j < this.options.sortable.length - 1; j++) {
  csv =  csv + (j > 0 ? ',':'')  + String(this.detections_filtered[i][this.options.sortable[j]]).replace(',',' ')
  }
  csv = csv + '\n'
  }
var filename = this.aoi.name + ' ' + this.yymm_item.yymm_label + ' ' + (this.typ_item.typ == 'gn' ? 'Green To Non Green' : 'Non Green To Green')

  const blob = new window.Blob([csv], { type: 'text/plain;charset=utf-8' })
  
   FileSaver.saveAs(blob, filename + '.csv')  
   },
  cateType(item){
    this.chgBtn = item.label
    this.typ_item = item
    this.changeTyp();
  },
  preview_chg(e){
  console.log(e)
  if (document.getElementById(this.last_preview_id))
  {
  
  this.tbl.unselectRow(this.last_preview_id)
  var chk0 = document.getElementById(this.last_preview_id)
  
  chk0.disabled = false
  chk0.checked = false
  var span0 = document.getElementById('span-' + this.last_preview_id)
   span0.style.cursor = "default"
  span0.textContent = 'Preview'
  }
  
  var chk = document.getElementById(e.id)
  var span = document.getElementById('span-' + e.id)
  if (chk.checked) {
  this.last_preview_id = e.id
  chk.disabled = true
  e.preview = 'Load Preview'
  span.style.cursor = "pointer"
  span.textContent = 'Load Preview'
   this.zoom(e)
  
  }
  },
  span_preview_click(e) {
 
 var span = document.getElementById('span-' + e.id)
 
 if (span.textContent == 'Load Preview')
 {
 this.tbl.unselectRow(this.last_preview_id)
 this.loaded_previews.push(e.id)
	this.select_row(e)
	 span.style.cursor = "default"
  span.textContent = 'Loaded'
  this.last_preview_id = ''
 }
 
  },  
preview_restore() {
	 this.detections_filtered.forEach((d) => { 
	
	var span = document.getElementById('span-' + d.id)
 
  var chk = document.getElementById(d.id)
 chk.checked = false
  chk.disabled = false
 	 span.style.cursor = "default"
  span.textContent = 'Preview'
 	
	 }
	 )
	
	setTimeout(this.preview_restore2,200)
	
},
preview_restore2() {
  console.log(this.last_preview_id)
 if (document.getElementById(this.last_preview_id))
  {

  var chk = document.getElementById(this.last_preview_id)
  var span = document.getElementById('span-' + this.last_preview_id)
  chk.checked = true
   chk.disabled = true
  span.style.cursor = "pointer"
  span.textContent = 'Load Preview'
   
  }
  
	 this.loaded_previews.forEach((id) => { 
	console.log(id)
	  var chk = document.getElementById(id)
	var span = document.getElementById('span-' + id)
if (chk)
{
 chk.checked = true
  chk.disabled = true

 	 span.style.cursor = "default"
  span.textContent = 'Loaded'
 }	
	 }
	 )		   
},  
sorted(e) {
console.log(e)

this.sort_option = e
this.preview_restore()

},  
  period_mmmyy(yymm) {
  
  return this.mmmyy(this.last_yymm(yymm)) + ' TO ' + this.mmmyy(yymm)
	
  },
  mmmyy(yymm) {
  if (!yymm)
  return
 var lst = yymm.split('-')
var y = lst[0]
var m = Number(lst[1]) 
return this.mmm_lst[m - 1].toUpperCase() + ' ' + y
  },  
  set_state(){
return
  if (localStorage.getItem('yymm_item') == '0')
  return
  
 this.yymm_item = JSON.parse(localStorage.getItem('yymm_item')) 
 this.aoi = JSON.parse(localStorage.getItem('aoi')) 
 this.typ_item = JSON.parse(localStorage.getItem('typ_item')) 
 
 		this.yymm = this.yymm_item.yymm
		
		window.left_label.textContent = this.mmmyy(this.last_yymm(this.yymm))
		window.right_label.textContent = this.mmmyy(this.yymm)

 this.detections = JSON.parse(localStorage.getItem('detections')) 				

  this.detections_filtered = this.detections.filter(d => d.typ == this.typ_item.typ)
  
    this.$emit('zoomToImage', this.aoi)
 
  this.loaded_previews = JSON.parse(localStorage.getItem('loaded_previews')) 
 setTimeout(this.preview_restore2,50) 
  
  },  
  save_state(){
  return
 localStorage.setItem('yymm_item', JSON.stringify(this.yymm_item))
 localStorage.setItem('aoi', JSON.stringify(this.aoi))
 localStorage.setItem('typ_item', JSON.stringify(this.typ_item)) 
 localStorage.setItem('loaded_previews', JSON.stringify(this.loaded_previews))
 localStorage.setItem('detections', JSON.stringify(this.detections))
  },
  showChangeDetection() {
  this.tblShow = true
  this.$emit('showChangeDetection')
   // setTimeout(this.save_state,0)
  },  
  hideChangeDetection() {
  this.tblShow = false
  this.$emit('hideChangeDetection')
   // setTimeout(this.save_state,0)
  },
 select_row(e) {
 
// e = e[0]
 
 var lst = e.ullr.split(',')

var bbox = [parseFloat(lst[0]),parseFloat(lst[1]),parseFloat(lst[2]),parseFloat(lst[3])]

  
this.$emit('zoomToImage', {bbox:[parseFloat(lst[0]),parseFloat(lst[1]),parseFloat(lst[2]),parseFloat(lst[3])]})


window.wms(this.yymm  + '_' + e.aoi + '_Change_' + e.id,'selected',"qa_id=" + e.id,bbox )

  this.$emit('zoomToImage', {bbox:bbox})

	var tile_url1 = this.images[this.last_yymm(this.yymm) + '-' + e.aoi]
	var tile_url2 = this.images[this.yymm + '-' + e.aoi]
	
	var id1 = this.last_yymm(this.yymm) + '_' + e.aoi + '_Mosaic'
	var id2 = this.yymm + '_' + e.aoi + '_Mosaic'

	var bbox = this.aois_hash[e.aoi].bbox
	
   const img1 = {
    "id": id1,
    "tile_url": tile_url1,
    "bbox": bbox,
    "min_zoom": 1,
    "max_zoom": 18,
    "type": "mosaic",
    "name": id1,
    "layoutRight": false,
    "layoutLeft": true,
	                "data_type": "raster_image",
                "selected": true,
                "opacity": 1,
                 "displayLabel": true,
                "map_type": "raster_image",
			"color": "#00DDDD"
  }

   const img2 = {
    "id": id2,
    "tile_url": tile_url2,
    "bbox": bbox,
    "min_zoom": 1,
    "max_zoom": 18,
    "type": "mosaic",
    "name": id2,
    "layoutRight": true,
    "layoutLeft": false,
	                "data_type": "raster_image",
                "selected": true,
                "opacity": 1,
                 "displayLabel": true,
                "map_type": "raster_image",
				 "color": "#00DDDD"
  }
  
  this.clear_preview_mosaic()
  
  this.$emit('changeListLayer', img1)
   this.$emit('changeListLayer', img2)  
   
   var id = e.aoi
   
   var aoi = this.aois_hash[e.aoi]

var i = 
{
                "id": id,
                "name": id,
 
                "list_values": [],
				"geometry" :  {
"type": "FeatureCollection",
"features": [aoi]
},
                "bbox": aoi.bbox,
                "type": "AOI",
                "data_type": "vector",
                "selected": true,
				"layoutRight": true,
                "opacity": 1,
                "layoutLeft": true,
                "displayLabel": true,
                "map_type": "vector",
                "color": "#FF0000"
            }
			
 // this.$emit('zoomToImage', i)
this.$emit('changeListLayer', i)
  
  },  
 zoom(e) {
 
 this.tbl.selectRow(e.id)
 
  var lst = e.ullr.split(',')
  
  this.$emit('zoomToImage', {bbox:[parseFloat(lst[0]),parseFloat(lst[1]),parseFloat(lst[2]),parseFloat(lst[3])]})
  
  
	var tile_url1 = this.images[this.last_yymm(this.yymm) + '-' + e.aoi]
	var tile_url2 = this.images[this.yymm + '-' + e.aoi]
	
	//var id1 = this.last_yymm(this.yymm) + '_' + e.aoi + '_Preview'
	//var id2 = this.yymm + '_' + e.aoi + '_Preview'

	var id1 = 'Preview1'
	var id2 = 'Preview2'

	var bbox = this.aois_hash[e.aoi].bbox
	
   const img1 = {
    "id": id1,
    "tile_url": tile_url1,
    "bbox": bbox,
    "min_zoom": 1,
    "max_zoom": 18,
    "type": "mosaic",
    "name": id1,
    "layoutRight": false,
    "layoutLeft": true,
	                "data_type": "raster_image",
                "selected": true,
                "opacity": 1,
                 "displayLabel": true,
                "map_type": "raster_image",
			"color": "#00DDDD"
  }

   const img2 = {
    "id": id2,
    "tile_url": tile_url2,
    "bbox": bbox,
    "min_zoom": 1,
    "max_zoom": 18,
    "type": "mosaic",
    "name": id2,
    "layoutRight": true,
    "layoutLeft": false,
	                "data_type": "raster_image",
                "selected": true,
                "opacity": 1,
                 "displayLabel": true,
                "map_type": "raster_image",
				 "color": "#00DDDD"
  }
  
  
  this.clear_preview_mosaic()
  
  this.add_preview_mosaic(window.map2,img1)
   this.add_preview_mosaic(window.map3,img1)
  this.add_preview_mosaic(window.map,img2)
  
  
  this.preview_mosaic1_id = img1.id
  this.preview_mosaic2_id = img2.id
  
  window.select_wms(window.map,'selected','selected',"qa_id=" + e.id)
  window.select_wms(window.map2,'selected','selected',"qa_id=" + e.id)
  window.select_wms(window.map3,'selected','selected',"qa_id=" + e.id)
  
  },
  
last_yymm(yymm) {
if (!yymm)
return

var lst = yymm.split('-')
var y = Number(lst[0])
var m = Number(lst[1])

var y1 = y
var m1 = m - 1

if (m1 < 1)
{
m1 = 12
y1 = y1 - 1
}

return String(y1) + '-' + (m1 < 10 ? '0' : '') + String(m1)
},
async  getDetectionsx(where){
 
 if (!this.yymm || !where)
 return
 
  const count_res = await getDetectionsx_Count(this.yymm,where)

	const count = count_res.data
	
	//const count = 200
	
	const pgsize = 4000
	
	var fetch = 0

	this.detections = []
	
	while (fetch < count)
	{
	
	  const res = await getDetections(this.yymm,where,fetch,pgsize)

	  //state.all_Detections = res.data.data
	  	
	this.detections = this.detections.concat(res.data)
	
	fetch = fetch + pgsize
	}
	
	 this.detections.forEach((d) => { 
	// d.typ_label = d.typ == 'gn' ? 'Green to Non Green' : 'Non Green to Green'
	 d.id1 = this.yymm + '_' + d.id
	d.prov = this.aoi.properties.province
	 d.mine = this.aoi.properties.mine
	 d.aoi_area = this.aois_hash[d.aoi].area.toFixed(2)
	 d.pchg = (d.area/d.aoi_area * 100).toFixed(2)
	 d.preview = 'Preview'
	 }
	 )
	
  window.select_wms(window.map,this.yymm  + '_' + this.aoi.name + '_Changes','detect',"typ = '" + this.typ_item.typ + "' and aoi='" + this.aoi.name.replace('&','%26') + "' and after_del_mth = '" + this.yymm + "'")
  window.select_wms(window.map2,this.yymm  + '_' + this.aoi.name + '_Changes','detect',"typ = '" + this.typ_item.typ + "' and aoi='" + this.aoi.name.replace('&','%26') + "' and after_del_mth = '" + this.yymm + "'")
  window.select_wms(window.map3,this.yymm  + '_' + this.aoi.name + '_Changes','detect',"typ = '" + this.typ_item.typ + "' and aoi='" + this.aoi.name.replace('&','%26') + "' and after_del_mth = '" + this.yymm + "'") 
 
 this.detections_filtered = this.detections.filter(d => d.typ == this.typ_item.typ)

  },
async  getDetections(){
 
 if (!this.yymm || (this.aoi && !this.aoi.geometry))
 return
 
 this.loaded_previews = []
 
  const count_res = await getDetections_Count(this.yymm,this.aoi.name)

	const count = count_res.data
	
	//const count = 200
	
	const pgsize = 4000
	
	var fetch = 0

	this.detections = []
	
	while (fetch < count)
	{
	
	  const res = await getDetections(this.yymm,this.aoi.name,fetch,pgsize)

	  //state.all_Detections = res.data.data
	  	
	this.detections = this.detections.concat(res.data)
	
	fetch = fetch + pgsize
	}
	
	 this.detections.forEach((d) => { 
	// d.typ_label = d.typ == 'gn' ? 'Green to Non Green' : 'Non Green to Green'
	 d.id1 = this.yymm + '_' + d.id
	d.prov = this.aoi.properties.province
	 d.mine = this.aoi.properties.mine
	 d.aoi_area = this.aois_hash[d.aoi].area.toFixed(2)
	 d.pchg = (100 * d.area/(this.aois_hash[d.aoi].area * 100)).toFixed(2)
	 d.preview = 'Preview'
	 }
	 )

	var tile_url1 = this.images[this.last_yymm(this.yymm) + '-' + this.aoi.name]
	var tile_url2 = this.images[this.yymm + '-' + this.aoi.name]
	
	//var id1 = this.last_yymm(this.yymm) + '_' + e.aoi + '_Preview'
	//var id2 = this.yymm + '_' + e.aoi + '_Preview'

	var id1 = 'Preview1'
	var id2 = 'Preview2'

	var bbox = this.aois_hash[this.aoi.name].bbox
	
   const img1 = {
    "id": id1,
    "tile_url": tile_url1,
    "bbox": bbox,
    "min_zoom": 1,
    "max_zoom": 18,
    "type": "mosaic",
    "name": id1,
    "layoutRight": false,
    "layoutLeft": true,
	                "data_type": "raster_image",
                "selected": true,
                "opacity": 1,
                 "displayLabel": true,
                "map_type": "raster_image",
			"color": "#00DDDD"
  }

   const img2 = {
    "id": id2,
    "tile_url": tile_url2,
    "bbox": bbox,
    "min_zoom": 1,
    "max_zoom": 18,
    "type": "mosaic",
    "name": id2,
    "layoutRight": true,
    "layoutLeft": false,
	                "data_type": "raster_image",
                "selected": true,
                "opacity": 1,
                 "displayLabel": true,
                "map_type": "raster_image",
				 "color": "#00DDDD"
  }
  
  
  this.clear_preview_mosaic()
  
  this.add_preview_mosaic(window.map2,img1)
   this.add_preview_mosaic(window.map3,img1)
  this.add_preview_mosaic(window.map,img2)
  
  
  this.preview_mosaic1_id = img1.id
  this.preview_mosaic2_id = img2.id
  
//window.select_wms(this.yymm  + '_' + this.aoi.name + '_Changes','detect',"aoi='" + this.aoi.name.replace('&','%26') + "' and after_del_mth = '" + this.yymm + "'",this.aois_hash[this.aoi.name].bbox )
  window.select_wms(window.map,this.yymm  + '_' + this.aoi.name + '_Changes','detect',"typ = '" + this.typ_item.typ + "' and aoi='" + this.aoi.name.replace('&','%26') + "' and after_del_mth = '" + this.yymm + "'")
  window.select_wms(window.map2,this.yymm  + '_' + this.aoi.name + '_Changes','detect',"typ = '" + this.typ_item.typ + "' and aoi='" + this.aoi.name.replace('&','%26') + "' and after_del_mth = '" + this.yymm + "'")
  window.select_wms(window.map3,this.yymm  + '_' + this.aoi.name + '_Changes','detect',"typ = '" + this.typ_item.typ + "' and aoi='" + this.aoi.name.replace('&','%26') + "' and after_del_mth = '" + this.yymm + "'") 

this.detections_filtered = this.detections.filter(d => d.typ == this.typ_item.typ)

	setTimeout(this.preview_restore,200)

 },
clear_preview_mosaic() {
if (window.map.getStyle().layers.some(layer => layer.id === this.preview_mosaic2_id)) window.map.removeLayer(this.preview_mosaic2_id).removeSource(this.preview_mosaic2_id)
if (window.map2.getStyle().layers.some(layer => layer.id === this.preview_mosaic1_id)) window.map2.removeLayer(this.preview_mosaic1_id).removeSource(this.preview_mosaic1_id)
if (window.map3.getStyle().layers.some(layer => layer.id === this.preview_mosaic1_id)) window.map3.removeLayer(this.preview_mosaic1_id).removeSource(this.preview_mosaic1_id)

},
add_preview_mosaic(map,image)
   {  
 		
if (map.getStyle().layers.some(layer => layer.id === image.id)) map.removeLayer(image.id).removeSource(image.id)
		
	
 map.addSource(image.id, {
        'type': 'raster',
        'tiles': [image.tile_url],
        'tileSize': 256,
        'maxzoom': image.max_zoom || 18,
        'minzoom': image.min_zoom || 1,
        'bounds': image.bbox
      })
      const layer = {
        id: image.id,
        'tile_url': image.tile_url,
        'bbox': image.bbox,
        'color': 'black',
        'type': 'raster',
        'source': image.id,
        'name': image.name,
        'display': 'visible',
        'bounds': image.bbox,
        'layout': {
        },
        'paint': {
          'raster-fade-duration': 0
        }
      }

map.addLayer(layer)	  
  },  
  select_aoi2(aoi)
   {
   this.select_aoi(aoi,window.map)
    this.select_aoi(aoi,window.map2)
	this.select_aoi(aoi,window.map3)
   },
select_aoi(aoi,map)
   {  
  
  		if (window.selected_aoi_id)
		{
		
		if (map.getStyle().layers.some(layer => layer.id === window.selected_aoi_id)) map.removeLayer(window.selected_aoi_id).removeSource(window.selected_aoi_id)
		}
	
var id = aoi.name

	
      map.addSource(id, {
        'type': 'geojson',
        'data': {
"type": "FeatureCollection",
"features": [aoi]
}
      })
      map.addLayer({
        'id': id,
        'type': 'line',
        'source': id,
        'layout': {},
        'paint': {
          'line-color': '#ff0000',
          'line-width': 2
        }
      })	

map.moveLayer(id)
window.selected_aoi_id = id
	  
  },
  changeTyp () {
	
      // this.getDetections()

 window.select_wms(window.map,this.yymm  + '_' + this.aoi.name + '_Changes','detect',"typ = '" + this.typ_item.typ + "' and aoi='" + this.aoi.name.replace('&','%26') + "' and after_del_mth = '" + this.yymm + "'")
  window.select_wms(window.map2,this.yymm  + '_' + this.aoi.name + '_Changes','detect',"typ = '" + this.typ_item.typ + "' and aoi='" + this.aoi.name.replace('&','%26') + "' and after_del_mth = '" + this.yymm + "'")
  window.select_wms(window.map3,this.yymm  + '_' + this.aoi.name + '_Changes','detect',"typ = '" + this.typ_item.typ + "' and aoi='" + this.aoi.name.replace('&','%26') + "' and after_del_mth = '" + this.yymm + "'") 
 	  
this.detections_filtered = this.detections.filter(d => d.typ == this.typ_item.typ)


	   
    },  
  changeYYMM () {
		this.yymm = this.yymm_item.yymm
		
		window.left_label.textContent = this.mmmyy(this.last_yymm(this.yymm))
		window.right_label.textContent = this.mmmyy(this.yymm)
		
       this.getDetections()
    },
changeAOI (x) {
    this.aoi = x
		console.log(this.aoi)
		//return
		this.select_aoi2(this.aoi)
		
		this.$emit('zoomToImage', this.aoi)
		//console.log(this.groups)
     this.getDetections()
    },

    onFeatureSel(id) {
      let data = this.localData.find((x) => x.id == id)
      this.gridApi.ensureNodeVisible(data)
      const node = this.gridApi.getRowNode(id)
      if (!node) return
      node.setSelected(true)
    },
    onFeatureUnSel(id) {
 
      map.call('CameraModule', 'flyToGeoJSON', geojson, {displayFeature: false})
    },
 
  }
}
</script>


<style>

.sticky {position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

input[type="checkbox"]:disabled {
  
  color: blue;
}

.VueTables__row--selected {
    background-color: #e5956d;
}

.margin_auto {
    margin: auto;
	width: 50%;
}


td {
  z-index: -1;
  text-align: center;
}
</style>

<style scoped>
.ag-header-container {

width : 1200px;

}

.row {
  display:flex;
  flex-direction:row;
  align-items: center;
  width: 100%;
}

.column {
  display:flex;
  flex-direction:column;
  align-items: flex-start;
}

.table-wrapper {
  height: 100%;
  background-color: white;
}

.table-content {
  padding: 10px;
  max-height: calc(100% - 15px);
}

.table-title {
  padding: 8px 20px;
  color: steelblue;
  border-top: 1px gainsboro solid;
  border-bottom: 1px gainsboro solid;
}

.table-title span {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.content-message {
  width: 100%;
  color: rgba(134, 134, 134, 0.7);
  font-size: 14px;
  text-align: center;
}

.table-tag:hover {
  cursor: pointer;
}
.action-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  font-size: 12px;
  color: white;
  background-color: var(--color-primary-dark);
  border-radius: 5px;

}

.headerBorder{
  border-top: solid 3px #893ff2;
  border-bottom: solid 3px #893ff2;
  margin-top: 1px; 
  padding-bottom : 10px;
}

div.table-content{

padding-top : 0px;
 height: 440px;
 
  overflow-y: auto;
  overflow-x: hidden; 
}

</style>

<style>

table.VueTables__table.table.table-striped.table-bordered.table-hover, 
table.VueTables__table.table.table-striped.table-bordered.table-hover tr th, 
table.VueTables__table.table.table-striped.table-bordered.table-hover tr td{
  border: 0.909091px solid rgba(137, 63, 242, 0.5);
  background-color: #ffffff;
}
table.VueTables__table.table.table-striped.table-bordered.table-hover tr th:nth-child(even) {
 /* padding: 10px 10px; */
  color: #893ff2;
  background: linear-gradient(0deg, #EFE7FB, #EFE7FB), #C2C9D1;
}

table.VueTables__table.table.table-striped.table-bordered.table-hover tr th:nth-child(odd) {
  padding: 10px 10px;
  color: #893ff2;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #C2C9D1;
}

table.VueTables__table.table.table-striped.table-bordered.table-hover tr td{
  padding: 5px 5px;
  border-bottom: solid 1px #DFCEF8;
}

tr.VueTables__row  td:nth-child(2){
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16.5px;
  line-height: 18px;
  color: #893ff2;
}

tr.VueTables__row  td:nth-child(3){
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16.5px;
  line-height: 18px;
  color:  #3E66FB;
}

tr.VueTables__row  td:nth-child(4){
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16.5px;
  line-height: 18px;
  color: #EF5DA8;
}

tbody{

zoom : 90%;
	max-height: 300px;
  overflow-y: auto;    
  overflow-x: hidden; 
    z-index: -1;
}

</style>
