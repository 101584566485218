<template>
  <v-layout style="width: 100%; height: 100%">
    <UserList/>
  </v-layout>
</template>

<script>
import UserList from '@/components/user/UserList'

export default {
    components: { UserList }
}
</script>
