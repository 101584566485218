<template>

        <v-card flat class="fill-height d-flex flex-column pt-4">
            <!-- <span @click="() => $emit('hideSideBar')" style="font-size: 14px; color: #893FF2; cursor: pointer"><v-icon color="#893FF2">mdi-chevron-double-left</v-icon>Hide Sidebar</span> -->

              <p class="text-body2 panelTabHeader pl-2 pb-2">DAILY VIEW</p>
              <v-row class="mx-1" style="border-radius: 11px; border: solid 1px #893FF2; height:40px;">
                <v-col cols="6" class="pa-2 pl-1">
                                <dualDate 
                @changedDate='newDate' 
                :disableTime="true">
              </dualDate>
                </v-col>
                <v-col cols="6" class="pa-2 searchBoxPosition" style="border-left: solid 2px #C4C4C4; border-radius: 12px; height: 40px;">
                  <searchBox @aoiSelected="newAOI"></searchBox>
                </v-col>
              </v-row>
          <div style="width: 100%; height: 10px;" class="py-5">
            <v-layout class="px-3 mb-4" style="height: 50px">
              <!-- <div style="width: 45%" class="mr-2">
                <date-picker  @input="changeDate" :label="'From date'" :max.sync="date.to" v-model="date.from" @change="changeAOI"/>

              </div>
              <div style="width: 45%" class="ml-2">
                <date-picker @input="changeDate" :label="'To date'" :min.sync="date.from" v-model="date.to" @change="changeAOI"/>
  
              </div> -->
            </v-layout>
                 
                  <v-layout class="pr-10">
                    <v-slider
                        v-if="1 == 2"
                        v-model="cloud"
                        @change="changeCloud()"
                        :max="100"
                        :min="0"
                        step="10"
                        thumb-label
                        :tick-labels="cloudLabels"
                        ticks="always"
                        track-color="#893FF2"
                    ></v-slider>
                  </v-layout>
                 
	   
          </div>
          <v-layout column class="overflow-y-auto pb-3 resultsContainer" style="height: 100%">
            <v-expansion-panels multiple flat style="height:455px;" class="s-expansionPanel">
              <v-expansion-panel v-for="(group, idx) of groups" :key="idx">
                <v-expansion-panel-header>
                  <span style="text-transform: uppercase; font-weight: bold"><v-icon class="mr-3">mdi-folder-outline</v-icon>{{group.name}}</span>
                  <v-spacer/>
                  <span class="mr-2" style="text-align: end; font-size: 12px;">(Total: {{group.images.length}} image)</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <!-- {{
                      var items = groups.images.id.split("_")
                      
                    }} -->
                  <v-list dense>
                            <v-list-item v-for="(item, index) in group.images" class="elevation-1 my-2 pl-0" :key="index" style="border-radius: 5px; border-left: solid 5px #536dfe" @click="itemclick(item)">
                              <v-layout column>
                                
                                <v-layout style="height: 35px" align-center>
                                  <!--<div style="border-bottom-left-radius: 5px; border-top-left-radius: 5px; height: 40px; width: 5px;" :style="{'background-color': item.color}" class="mr-3"></div>-->
                                  <v-layout class="fill-height ml-3" align-center   
                                      @mouseenter="showImageBnd(item)"
                                      @mouseleave="removeImageBnd">
                                    <span style="font-size: 13px">{{item.name}}</span>
                                    <v-spacer/>
                                    <img :src="item.thumb" width="50px" height="50px"/>
                                  </v-layout>
                                </v-layout>
                                    <v-row>
                                <v-col cols="4" class="text-center">
                                  <v-icon>mdi-weather-cloudy</v-icon> {{item.properties.cloud_cover}}
                                </v-col>
                                <v-col cols="4" class="text-center">
                                  <v-icon>mdi-timer-outline</v-icon> {{ ("0" + new Date(item.properties.updated).getHours()).slice(-2)  }}:{{ ("0" + new Date(item.properties.updated).getMinutes()).slice(-2) }}:{{ ("0" + new Date(item.properties.updated).getSeconds()).slice(-2)}}
                                </v-col>
                                <v-col cols="4" class="text-center">
                                  <v-icon>mdi-angle-acute</v-icon> {{item.properties.view_angle}}
                                </v-col>
                              </v-row>
                              </v-layout>
                            </v-list-item>
                  </v-list>





                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-layout>
        </v-card>

</template>

<script>
import aois_geojson from '@/assets/aoisx.json'
import FullScreenSquare from "@/components/imagery/FullScreenSquare";
import {mapState} from "@/store/ults";
import Store from "@/store";
import DatePicker from "@/components/DatePicker";
import search from "@/components/home/aoiMenu";
import dualDate from "@/components/dualDateRangePicker";

export default {
  name: "Daily",
  components: {
    DatePicker, 
    FullScreenSquare,
    'searchBox' : search, 
    dualDate   
    },
  data () {
    return {
      cloudLabels : [0,10,20,30,40,50,60,70,80,90],
      cloud : 30,
	    //groups : {},
	    aoi : {},
      aois: [],
      tabs: 'data',
      mode: 'type',
      //dateSelection: require('@/assets/dateSelect.json'),
      date: {from : '2020-01-01' , to : '2020-02-20'},
      aoiProvince : 'province',
      items: ''
    }
  },
  props: {
   //groups: {type: Array, default: () => []}, 
  },
  watch: {

  },
  computed: {
    ...mapState("point", ["currentPoint"]),
	  ...mapState('image', ['groups']),
  },
  mounted() {
  aois_geojson.features.forEach((f) => {
    var x1 = 999999999
    var x2 = -999999999
    var y1 = 999999999
    var y2 = -999999999
    var c = f.geometry.coordinates[0]

    for (var a = 0; a < c.length; a++ ) {
      if (c[a][0] < x1)
        x1 = c[a][0]
      if (c[a][0] > x2)
        x2 = c[a][0]	
      if (c[a][1] < y1)
        y1 = c[a][1]
      if (c[a][1] > y2)
        y2 = c[a][1]		
    }
      
      f.bbox =  [x1,y1,x2,y2
]
      
      })
    this.aois = aois_geojson.features
    this.date.from = this.fromDate
    this.date.to = this.toDate
    // console.log(this.groups)
  },
  methods: {
      newDate(date){
        if (date[1]){
          this.date.from = date[0].split("-").reverse().join("-")
          this.date.to = date[1].split("-").reverse().join("-")
        }
        //console.log('AOI of New Date:', this.aoi)
        if(this.aoi.properties.name = undefined){
          //console.log('Date: ',date, ' AOI: ', this.aoi)
          return
        }
        else if (this.aoi.name) {
          this.changeAOI();
        }
      },
      newAOI(x){
        // console.log('new AOI',x)
        this.aoi = x;

        // console.log('New AOI: ' ,this.aoi)
        if(this.date.from && this.date.to){ 
          this.changeAOI();
        }
      },
      itemclick(i) {
        // Emmitted when layer results are clicked
        this.$emit('zoomToImage', i)
        this.$emit('changeListLayer', i)
      },
      showImageBnd(i){
        var id = 'imgbnd'
            map.addSource(id, {
              'type': 'geojson',
              'data': {
                "type": "FeatureCollection",
                "features": [
                      { 
                        "type": "Feature" , 
                        "geometry" : i.geometry
                      }
                    ]
                    }
                })
            map.addLayer({
              'id': id,
              'type': 'line',
              'source': id,
              'layout': {},
              'paint': {
                'line-color': '#ffa500',
                'line-width': 2
              }
            })	

            map.moveLayer(id)
      //console.log(i)
        },
        removeImageBnd(){
          var id = 'imgbnd'
          if (map.getStyle().layers.some(layer => layer.id === id)) map.removeLayer(id).removeSource(id)
            
        },  
        select_aoi(aoi)  {
                      var map = window.map
                        if (window.selected_aoi_id)
                        { 
                          // console.log()
                          if (map.getStyle().layers.some(layer => layer.id === window.selected_aoi_id)) map.removeLayer(window.selected_aoi_id).removeSource(window.selected_aoi_id)
                        }
                        // console.log('Select AOI', window.selected_aoi_id);
                      // var id = aoi.properties.layer
                      // var id = aoi.properties.name
                      var id = aoi.name
                      var i = 
                      {
                        "id": id,
                        "name": id,
                        "list_values": [],
                        "geometry" :  {
                          "type": "FeatureCollection",
                          "features": [aoi]
                        },
                        "bbox": aoi.bbox,
                        "type": "AOI",
                        "data_type": "vector",
                        "selected": true,
                        "layoutRight": true,
                        "opacity": 1,
                        "layoutLeft": true,
                        "displayLabel": true,
                        "map_type": "vector",
                        "color": "#FF0000"
                      }
                      // console.log(i);
              this.$emit('zoomToImage', i)
              this.$emit('changeListLayer', i)

      /*	
            map.addSource(id, {
              'type': 'geojson',
              'data': {
      "type": "FeatureCollection",
      "features": [aoi]
      }
            })
            map.addLayer({
              'id': id,
              'type': 'line',
              'source': id,
              'layout': {},
              'paint': {
                'line-color': '#ff0000',
                'line-width': 2
              }
            })	

      map.moveLayer(id)
      */
      window.selected_aoi_id = id
          
        },
        changeDate () {
              this.getimgs()
            },
        changeAOI () {
              // console.log(this.aoi);
              //console.log('Error from Select')
              this.select_aoi(this.aoi)
              // console.log('Done with select AOI')
              //console.log('Done with Select')
              //console.log('Error from Zoom')
              this.$emit('zoomToImage', this.aoi)
              //console.log('Done with Zoom')
              //console.log(this.groups)
              // console.log('Error from Img')
              this.getimgs()
              //console.log('Done with getImg')
          },
        updateCloud () {
          this.getimgs()
            },
          
        getimgs() {
          // console.log('Sending Request before Error?')
          // console.log('AOI: ' ,this.aoi, 'Date: ', this.date, 'Cloud: ', this.cloud)
            if (this.aoi && this.aoi.geometry && this.date.from && this.date.to)
            {
                this.$store.dispatch('image/getImages',{aoi: this.aoi , date: this.date, cloud : this.cloud})
            }
          }
    }
}
</script>

<style scoped>

.s-expansionPanel {
  display: block !important;
}

/deep/
.sidebar-tab .v-tabs-bar {
  height: 45px !important;
}
/deep/
.small-radio .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
  background: currentColor;
  transform: scale(0.9);
  transition: none;
}

div.v-menu__content.menuable__content__active{
  top: 323px !important;
}



</style>

<style>
.panelTabHeader{
  font-weight: bold;
  font-size: 14.5px;
  line-height: 17px;
  letter-spacing: 0.05em;
  color: #8539F0;
}

.searchBoxPosition button{
  transform: translate(-6px, -8px);
}

.searchBoxPosition button span{
  color: #8539F0;
  font-weight: 700;
}
</style>
