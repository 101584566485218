<template>
  <v-card width="100%" height="100%">
    <v-layout class="fill-height" column>
      <v-layout style="background-color: #f4ecfd">
        <div :style="{'border-top': tab === item.code ? '2px solid #893FF2' : ''}" v-for="(item, index) of sources">
          <v-btn @click="setCurrentData(item)" text width="240" :color="tab === item.code ? '#893FF2' : '#000'">{{item.name}}</v-btn>
        </div>
        <v-card width="200" height="30" style="position: absolute; top: 10px; right: 10px">
          <v-layout justify-center align-center class="fill-height">
            <v-btn-toggle
                mandatory
                v-model="mode"
                tile
                color="deep-purple accent-3"
                group
            >
              <v-btn
                  :style="{'border': mode === 'app'? '1px solid #893FF2' : '', 'border-radius': mode === 'app'? '5px' : '0'}"
                  value="app" x-small>
                Overview
              </v-btn>
              <v-btn
                  :style="{'border': mode === 'mapView'? '1px solid #893FF2' : '', 'border-radius': mode === 'mapView'? '5px' : '0'}"
                  value="mapView" x-small>
                Map view
              </v-btn>
            </v-btn-toggle>
          </v-layout>
        </v-card>
      </v-layout>
      <Datasource @gotoMapView="mode = 'mapView'" :dataSource.sync="tab" v-if="mode === 'app'"/>
      <Dashboard :currentDatasource.sync="tab" v-if="mode === 'mapView'"/>
    </v-layout>
  </v-card>
</template>

<script>
import BaseMap from "@/components/home/BaseMap"
import Overview from "@/components/home/overview/Overview"
import Datasource from "@/components/home/dataSource"
import Dashboard from "@/components/imagery/Dashboard";
export default {
  components: {Dashboard, Overview, BaseMap, Datasource },
  name: "index",
  data () {
    return {
      mode: 'app',
      sources: [
        {
          name: '1. PlanetScope (3M)',
          code: 'planetscope',
          isSource: true
        }
      ],
      isSource: false,
      tab: 'planetscope'
    }
  },
  methods: {
    goToLink(link) {
      if (this.$route.path !== link) this.$router.push({ path: link });
    },
    setCurrentData (data) {
      this.tab = data.code
      this.isSource = data.isSource
    },
  }
}
</script>

<style scoped>
/deep/
.tab-menu .v-tabs-slider-wrapper {
  top: 0 !important;
}
</style>
