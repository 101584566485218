<template>
  <v-layout style="width: 100%; height: 100%">
    <ImageDashboard/>
  </v-layout>
</template>

<script>
import ImageDashboard from '@/components/imagery/Dashboard'
export default {
    components: { ImageDashboard }
}
</script>
